











import Vue from "vue";

export default Vue.extend({
  name: "DataNotFound",
  props: {
    title: {
      type: String,
      default: "Sorry! We couldn't find any relevant data."
    }
  }
});
